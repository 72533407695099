// Global styles
:root {
  --white: #fff;
  --black: #000;
  --beige: #fdf6f2;
  --background-color: var(--beige);
  --link-color: var(--text-color);
  --key-color: #00b2ff;
  --text-color: var(--black);
  --text-color-light: #444;
  --text-color-light-1: #666;
  --text-color-lighter: rgb(150 150 150 / 66%);
  --link-color-red: rgb(222 12 43 / 94%);
  --text-color-red: rgb(255 0 107 / 62%);
  --link-underline-color: color-mix(in srgb, var(--key-color) 50%, rgb(255 255 255 / 0%));

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: none;
  font-weight: 400;
  line-height: 1;
  text-rendering: optimizelegibility;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;
}

body {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  background-image: url('/images/face.webp');
  background-position: right -125px bottom -25px;
  background-repeat: no-repeat;
  background-size: 250px;
  color: var(--text-color);
}

@media (width <= 1000px) {
  body {
    background-position: right -100px bottom -25px;
    background-repeat: no-repeat;
    background-size: 200px;
  }
}

// Links
a {
  color: var(--link-color);
  text-decoration: underline;
  text-decoration-color: var(--link-underline-color);
  text-decoration-thickness: .035em;
  text-underline-offset: .275em;
  transition: all .25s linear;
}

a:hover {
  color: var(--link-color-red);
  text-decoration-color: var(--link-color-red);
  text-decoration-thickness: .0475em;
}

// Typography
h1 {
  max-width: 728px;
  margin: 0 0 .5rem;
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.25;
}

h2 {
  max-width: 728px;
  margin: 0 0 .5rem;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.25;
}

h3 {
  max-width: 728px;
  margin: 0 0 .5rem;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.125;
}

p {
  width: 100%;
  max-width: 728px;
  margin: 0 0 .5rem;
  line-height: 1.5;
}

@media (width <= 540px) {
  h1 {
    font-size: 2.75rem;
  }
}

// Main container
.main {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

// Not found page
.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    padding: 1rem;
    font-size: 1.25rem;
  }
}
