.logo {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 8px 8px 45px -15px rgb(0 0 0 / 75%);

  .avatar {
    width: 100%;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .link {
    display: flex;
    border: none;
  }

  .openToWork {
    position: absolute;
    z-index: 1;
    bottom: -1rem;
    display: inline-block;
    width: fit-content;
    padding: .25rem .375rem;
    background-color: red;
    color: #fff;
    font-size: .75rem;
    font-weight: bold;
    pointer-events: none;
    text-align: center;
    text-transform: uppercase;

    &.showPanel {
      max-width: fit-content;
      background-color: green;
    }
  }
}
