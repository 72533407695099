.footer {
  display: flex;
  width: 100%;
  max-width: 1000px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3rem 1rem 2rem;

  div {
    display: flex;
    flex-direction: column;
    gap: .25rem;

    p {
      margin: 0;

      &.copyright {
        margin-bottom: .5rem;
      }

      &.email {
        margin-bottom: 1rem;
      }

      &.version {
        font-family: monospace;
        font-size: .875rem;

        a {
          color: var(--text-color-lighter);
          text-decoration: none;

          &:hover {
            color: var(--link-color-red);
          }
        }
      }
    }
  }
}

.socialLinks {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

.socialLink {
  color: var(--text-color-light);
  text-decoration: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  
  &:hover {
    transform: translateY(-2px);
    
    &[aria-label="Facebook"] {
      color: #1877f2;
    }
    
    &[aria-label="Linkedin"] {
      color: #0a66c2;
    }
    
    &[aria-label="Twitter"] {
      color: #1da1f2;
    }
    
    &[aria-label="Github"] {
      color: #333;
    }
    
    &[aria-label="Telegram"] {
      color: #0088cc;
    }
  }
}
